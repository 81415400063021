import React from "react";

import {
    Box,
    Button,
    Typography
} from "@material-ui/core";

import MemberNavigationMenu from "../../navigationmenu/src/MemberNavigationMenu.web";
import HomePageMemberController, { Props } from "../src/HomePageMemberController"
import { Info } from "./assets"
import NavigationHeader from "../../navigationmenu/src/NavigationHeader.web";
const navigate = require("react-navigation")
export default class HomePageMember extends HomePageMemberController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div><NavigationHeader navigation={this.props.navigation} id="" isMemberUser={true} />
            <Box style={webStyle.home}> 
                <MemberNavigationMenu {...this.props} />
                {this.state.communityList.length===0?<Box style={{ width: `calc(100vw - 295px)` }}>
                    <Box style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "168px" }}>
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            <Typography style={webStyle.text_color}>
                            <img src={Info} alt="Info" style={webStyle.info_icon} />
                                You haven't subscribed to any community yet. Support or follow communities to see posts in your feed.
                            </Typography>
                        </Box>
                        <Button style={webStyle.discover_button} onClick={this.handleNavigatetoDiscover}>Discover Communities</Button>
                    </Box>
                </Box>:<Box style={{width: `calc(100vw - 295px)`,height:"100vh",display:"flex",alignItems:"center",justifyContent:"center",color:"#ffffff",fontSize:"20px"}}>You have subscribed {this.state.communityList.length} communities</Box>
                }
            </Box>
            </div>
        );
    }
}



const webStyle = {
    home: {
        backgroundColor: '#090909',
        display: "flex",
        minHeight: "100vh"
    },
    text_color: {
        color: "#FFFFFF",
        textAlign: "center" as const,
        fontSize: "22px",
        maxWidth: "725px",
        fontFamily: "Montserrat"
    },
    info_icon: {
        width: "26.67px",
        height: "26.67px",
        transform:'translate(0px,4px)'
    },
    discover_button: {
        width: "439px",
        height: "64px",
        backgroundColor: "#3E01B9",
        marginTop:"20px",
        fontSize:"18px",
        color:"#FFFFFF",
        fontWeight:600,
        fontFamily:"Montserrat",
        textTransform:"none"
    } as React.CSSProperties
};
// Customizable Area End
