import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// export const configJSON = require("../config");
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCoverphoto: any;
  open: boolean;
  coverPhotos: any[];
  Open: boolean;
  email: any;
  gender: any;
  age_group: any;
  language: any;
  Country: any;
  phonenumber: any;
  emailErrorMsg: any;
  emailError: boolean;
  genderError:boolean;
  genderErrorMsg: any;
  CountryError:boolean;
  CountryErrorMsg: any;
  languageError:boolean;
  languageErrorMsg: any;
  phonenumberError:boolean;
  phonenumberErrorMsg: any;
  age_groupError:boolean;
  age_groupErrorMsg: any;
  ImageError:boolean;
  ImageErrorMsg: any;
  countryList: any;
  buttonstatus: boolean;
  languageList:any;
  errorResponse:any;
  phonestatus:boolean;
  emailStatus:boolean;
  full_name :any;
  Age_Group_values:any;
  Max_age:any;
  Min_age:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

class SetupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  getAccountDetails: any
  getCountryDetailsID: any;
  getLanguageListID:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

      // Customizable Area End
    ];

    // Customizable Area End
    this.state = {
      // Customizable Area Start
      Open: false,
      coverPhotos: [],
      selectedCoverphoto: "",
      open: false,
      email: "",
      emailError: false,
      emailErrorMsg: "",
      genderError:false,
      gender: "",
      genderErrorMsg: "",
      age_group: "",
      age_groupError:false,
      age_groupErrorMsg: "",
      language: "",
      languageError:false,
      languageErrorMsg: "",
      Country: "",
      CountryError:false,
      CountryErrorMsg: "",
      phonenumber: "",
      phonenumberError:false,
      phonenumberErrorMsg: "",
      ImageErrorMsg: "",
      ImageError:false,
      countryList: [],
      buttonstatus: false,
      languageList:[],
      errorResponse:"",
      phonestatus:false,
      emailStatus:false,
      full_name : "",
      Age_Group_values:[
      
          {
            id: 1,
            age_min: 18,
            age_max: 24,
            age_show: "18 - 24",
          },
          {
            id: 2,
            age_min: 25,
            age_max: 29,
            age_show: "25 - 29",
          },
          {
            id: 3,
            age_min: 30,
            age_max: 34,
            age_show: "30 - 34",
          },
          {
            id: 4,
            age_min: 35,
            age_max: 39,
            age_show: "35 - 39",
          },
          {
            id: 5,
            age_min: 40,
            age_max: 44,
            age_show: "40 - 44",
          },
          {
            id: 6,
            age_min: 45,
            age_max: 49,
            age_show: "45 - 49",
          },
          {
            id: 7,
            age_min: 50,
            age_max: 54,
            age_show: "50 - 54",
          },
          {
            id: 8,
            age_min: 55,
            age_max: 59,
            age_show: "55 & 59",
          },
          {
            id: 9,
            age_min: 60,
            age_max: 99,
            age_show: "60 & above",
          }
        
      ],
      Max_age:"",
      Min_age:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start



  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetCountrylist()
    this.getlanguageList()
    this.getListRequest()
    

    // Customizable Area End
  }


  Back = () => {
   
      this.props.navigation.goBack();
    
  };


  handleGetCountrylist = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryDetailsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryListWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClose = () => {

    this.setState({
      Open: false,
    });


  }
  handleOpen = () => {


    this.setState({ Open: true })
  }
  handleSelectImage = (event: any) => {

    this.setState({ selectedCoverphoto: event },()=>{this.handlebuttonstatus()})

  }

  handleAddCoverImage = (e: any) => {

    let newcover = e.target.files[0]


    this.setState({ coverPhotos: [...this.state.coverPhotos, { Img: newcover }], ImageErrorMsg: ""},()=>{
      this.handleValidation();
      this.handlebuttonstatus();
    })
  }

  handlePhonenumber = (e: any) => {
    
    if (e.length === 0 ) {
      this.setState({phonenumberError:true,phonenumberErrorMsg:"Please enter phone number",buttonstatus:false})
    }else {
      this.setState({phonenumber:e,phonenumberError:false,phonenumberErrorMsg: "" },()=>{
        this.handlebuttonstatus()
      })
    }

  }
  handlefieldschange = (event: any) => {
    let name = event.target.name
    let value = event.target.value

    if (name === "email") {

      this.setState({

        emailError: false,
        emailErrorMsg: ""
      })
    }
    
    if (name === "gender") {

      this.setState({ genderErrorMsg: "",genderError:false })
    }

    if (name === "age_group") {

      this.setState({age_groupError:false, age_groupErrorMsg: "" },()=>{this.handleage()})


    }
    if (name === "language") {
      this.setState({ languageError:false,languageErrorMsg: "" })
    }
    if (name === "Country") {
      this.setState({CountryError:false , CountryErrorMsg: ""})
    }
    
    this.setState(
      (prevalue) => ({
      
        ...prevalue,
        [name]: value
      }),()=>{
        this.handleValidation();
        this.handlebuttonstatus();

      }
      )
    
  }
 
  handlebuttonstatus = () => {
   
    let data = this.state
   
    if (!data.emailError && data.Country !== "" && data.age_group !== "" && data.language !== "" && data.selectedCoverphoto.length !== 0 && !data.phonenumberError) {
      this.setState({ buttonstatus: true })
    } else {
      this.setState({buttonstatus:false})
    }
  }
  handleValidation = () => {
    if (this.state.phonenumber === "") {
      this.setState({
        phonenumberError:true,
        phonenumberErrorMsg: "please enter the phone number"
      })
      return 
    }

    if (this.state.email === "") {

      this.setState({
        emailError: true,
        emailErrorMsg: "please enter the email",
      
      },()=>{this.handlebuttonstatus()})
      return
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(this.state.email)) {
      this.setState({
        emailError:true,
        emailErrorMsg: "Please enter valid email",
      },()=>{this.handlebuttonstatus()})
      return
    }

    if (this.state.gender === "") {
      this.setState({
         genderError:true,
         genderErrorMsg: "please select the gender"
       })
       
     }

    if (this.state.age_group === "") {
      this.setState({
       age_groupError:true,
       age_groupErrorMsg: "please select the age group"
     })
     
   }

    if (this.state.language === "") {
      this.setState({
        languageError:true,
        languageErrorMsg: "please select the language"
      })
    
    }
    
    if (this.state.Country === "") {
        this.setState({
        CountryError:true,
        CountryErrorMsg: "please select the Country"
        
      })
      
    }
    
  }
  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getCountryDetailsID) {
        if (!responseJson.errors) {
          this.setState({ countryList: responseJson.data })
        }
      }
      if(apiRequestCallId ===this.getLanguageListID) {
        
        this.setState({languageList:responseJson.languages})

      }
      if (apiRequestCallId === this.createAccountApiCallId) {
        this.handleCreateAccountResp(responseJson)
      
      }
      if (apiRequestCallId === this.getAccountDetails) {
        this.handleAccountDetails(responseJson)
       
      }
    }
  }
handleage=()=>{
  const raange = this.state.Age_Group_values.filter((data:any)=>{
    return data.age_show == this.state.age_group
  })
  this.setState({Max_age:raange[0]?.age_max,Min_age:raange[0]?.age_min})
  
  
}

  handleCreateAccountResp =(responseJson:any)=>{
    if (!responseJson.errors) {
          
      this.props.navigation.navigate("Communityforum3")
    } else {
      this.setState({errorResponse:Object.values(responseJson.errors[0])[0]})
    }
  }

  handleAccountDetails =(responseJson:any)=>{
    if (!responseJson.errors) {  
  
      if (responseJson.data.attributes.email !== null) {
      
        this.setState({email:responseJson.data.attributes.email,emailStatus:true,full_name:responseJson.data.attributes.full_name})
      }
      if (responseJson.data.attributes.full_phone_number !== "") {

        this.setState({phonenumber:responseJson.data.attributes.full_phone_number,phonestatus:true})
      }
    } else {
    
      this.setState({errorResponse:Object.values(responseJson.errors[0])[0]})
    }
  }
  getlanguageList = () =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLanguageListID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LanguagesApi
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getListRequest = () => {
    let loginToken = localStorage.getItem('authToken');


    const header = {

      token: loginToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointWeb
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  postData = () => {

    this.handleValidation()
    let Token = localStorage.getItem('authToken');
    const formdata = new FormData();
    formdata.append("data[attributes][full_phone_number]", this.state.phonenumber);
    formdata.append("data[attributes][gender]", this.state.gender);
    formdata.append("data[attributes][full_name]",this.state.full_name);
    formdata.append("data[attributes][profile_attributes][country]", this.state.Country);
    formdata.append("data[attributes][profile_attributes][photo]", this.state.selectedCoverphoto.Img, "[PROXY]");
    formdata.append("data[attributes][age_group]", this.state.age_group);
    formdata.append("data[attributes][native_languages][]", this.state.language);
    formdata.append("data[attributes][email]", this.state.email);
    formdata.append("data[attributes][min_age]", this.state.Min_age);
    formdata.append("data[attributes][max_age]", this.state.Max_age);

    const header = {

      token: Token
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointWeb,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


   
  };

  // Customizable Area End
}

export default SetupController;
