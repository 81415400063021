import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("../src/config");
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToggle: boolean;
  status: any;
  showToggle: boolean;
  loginDataErr: string;
  open: boolean;
  showPassword: boolean;
  dataRecord: any;
  loginError: any;
  emailErrors: any;
  phoneErrors: any;
  otpScreen: boolean;
  phoneNumber: string;
  accountType: 'creator'| 'member';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

class LogInController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: any;
  apiPhoneLoginCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      loginToggle: false,
      status: "",
      showToggle: false,
      loginDataErr: '',
      open: false,
      showPassword: false,
      dataRecord: "",
      loginError: null,
      emailErrors: false,
      phoneErrors: false,
      otpScreen: false,
      phoneNumber: "",
      accountType:'member'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.checkForAccountType();
    // Customizable Area End
  }

  // Customizable Area Start

  checkForAccountType=()=>{
    const type = new URLSearchParams(location.search).get('type') === 'creator'? 'creator': 'member';
    this.setState({accountType: type})
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiPhoneLoginCallId) {
          this.handlePhoneLoginReceiveData(responseJson)
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.handleEmailLoginReceiveData(responseJson)
        }
      } else {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ emailErrors: true });
        this.setState({ phoneErrors: true })
      }
    }
  }

  handleOtpBack = () => {
    this.setState({
      otpScreen: false
    })
  }

  handlePhoneLoginReceiveData = (responseJson: any) => {
    if (responseJson.data) {
      setStorageData('otpToken', responseJson.data.meta.otp_token);
      this.setState({
        otpScreen: true
      })
    } else {
      const err = responseJson.errors
      this.setState({
        phoneErrors: true,
        loginDataErr: err,
      });
    }
  }

  handleEmailLoginReceiveData = (responseJson: any) => {
    if (responseJson.errors) {
      const err = responseJson.errors
        .map((item: any) => Object.values(item)[0])
        .join(",");
      this.setState({
        emailErrors: true,
        loginDataErr: err,
      });
    }
    else {
      setStorageData('authToken', responseJson.meta?.token ?? '');
      const accountType = responseJson.meta?.account_type === 'creator' ? 'creator' : 'member';
      if (responseJson.meta?.is_profile_completed === true) {
        if (accountType === 'creator') {
          this.props.navigation.navigate('Communityforum3');
        } else {
          this.props.navigation.navigate('HomePageMember');
        }
      }
      else {
        this.props.navigation.navigate('Setup');
      }
    }
  }



  Back = () => {
    if (this.state.showToggle === true) {
      this.setState({ showToggle: false });
    } else {
      this.props.navigation.goBack();
    }
  };

  OnLogin = () => {
    this.props.navigation.navigate("Login");
  };
  handlePhoneOpen = () => {
    this.setState({ showToggle: true });
    this.setState({ loginToggle: true });
  };
  handleEamilOpen = () => {
    this.setState({ showToggle: true });
    this.setState({ loginToggle: false });
  };
  onHandleClose = () => {
    this.setState({ open: false });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onLginToggleOut = () => {
    this.setState({ loginToggle: false });
  };
  onLginToggleIn = () => {
    this.setState({ loginToggle: true });
  };


  postData = (values: any) => {
    const endpoint = `/bx_block_login/login`;
    const data = {
      type: "email_account",
      attributes: {
        email: values.email,
        password: values.password,
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  postPhoneData = (values: any) => {
    this.setState({
      phoneNumber: values.Phone
    })
    const data = {
      attributes: {
        full_phone_number: values.Phone,
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/login_otp`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Customizable Area End
}

export default LogInController;
