import React from "react";

import {
    Box,
    Typography,
    Grid,
    MobileStepper,
    styled
} from "@material-ui/core";

import MemberNavigationMenu from "../../navigationmenu/src/MemberNavigationMenu.web";
import DiscoverPageMemberController, { Props } from "../src/DiscoverPageMemberController"
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {
    ornament,
    mainCover,
    coverLogo,
    zilion,
    background_1,
    education, art,
    fitness,
    medical,
    technology,
    travel,
    leftarrow,
    FavIcon3,
    members,
    arrow,
    video
} from '../src/assets'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PrimaryData, animeData, secondaryData } from "../src/CardData"
const tutorialSteps = [

    {
        title: "Weird Planet stuffs",
        label: "25 Strange Images From Outer Space NASA Does Not Want Us To See. By Val Kornev ... We are a way for the universe to... Learn More",
        imgPath: background_1,
        logoImg: coverLogo
    },
    {
        title: "Find what you are looking for ",
        label: "thas been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        imgPath: mainCover,
        logoImg: zilion
    },
    {
        title: "  Find what you are looking forward ",
        label: "thas been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        imgPath: ornament,
        logoImg: coverLogo
    },
    {
        title: "Find what you are looking for ",
        label: "thas been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        imgPath: mainCover,
        logoImg: zilion
    },

];
const category = [
    {
        title: "Education",
        icon: education
    },
    {
        title: "Medical",
        icon: medical
    },
    {
        title: "Art",
        icon: art
    },
    {
        title: "Travel",
        icon: travel
    },
    {
        title: "Fitness",
        icon: fitness
    },
    {
        title: "Tecchnology",
        icon: technology
    },
]
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1424 },
        items: 6,
        slidesToSlide: 3
    },
    desktop: {
        breakpoint: { max: 1424, min: 1300 },
        items: 5,
        slidesToSlide: 3
    },

    tablet: {
        breakpoint: { max: 1300, min: 1100 },
        items: 4,
        slidesToSlide: 2
    },
    smallTablet: {
        breakpoint: { max: 1100, min: 900 },
        items: 3,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1
    },
};
const creatorResponsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1424 },
        items: 5,
        slidesToSlide: 3
    },
    desktop: {
        breakpoint: { max: 1424, min: 1300 },
        items: 4,
        slidesToSlide: 3
    },

    tablet: {
        breakpoint: { max: 1300, min: 1100 },
        items: 3,
        slidesToSlide: 2
    },
    smallTablet: {
        breakpoint: { max: 1100, min: 900 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1
    },
};

const CustomCarousel = styled(Carousel)({
    '& .react-multiple-carousel__arrow--right': {
        backgroundColor: "transparent !important"
    },
    '& .react-multiple-carousel__arrow--left': {
        backgroundColor: "transparent !important"
    },
    '& .react-multiple-carousel__arrow--right:before': {
        fontSize: "40px",
        fontWeight: 700
    },
    '& .react-multiple-carousel__arrow--left:before': {
        fontSize: "40px",
        fontWeight: 700
    }
})

const CustomGrid = styled(Grid)({
    '@media (min-width: 1560px) and (max-width: 1900px)': {
        maxWidth: '20%'
    },
    '@media (min-width: 1900px)': {
        maxWidth: '16%'
    }
})

const CustomCarouselPrimary = styled(Carousel)({
    '& .react-multiple-carousel__arrow--right': {
        backgroundColor: "transparent !important"
    },
    '& .react-multiple-carousel__arrow--left': {
        backgroundColor: "transparent !important"
    },
    '& .react-multiple-carousel__arrow--right:before': {
        fontSize: "40px",
        fontWeight: 700
    },
    '& .react-multiple-carousel__arrow--left:before': {
        fontSize: "40px",
        fontWeight: 700
    },
    '& .react-multi-carousel-track': {
        backgroundColor: "#000000",
        paddingTop: "3px"
    }
})
const CustomCarouselSecondary = styled(Carousel)({
    height: 300,
    paddingInline: 20,
    '& .react-multiple-carousel__arrow--right': {
        backgroundColor: "transparent !important",
        top: '25%'
    },
    '& .react-multiple-carousel__arrow--left': {
        backgroundColor: "transparent !important",
        top: "25%"
    },
    '& .react-multiple-carousel__arrow--right:before': {
        fontSize: "40px",
        fontWeight: 700
    },
    '& .react-multiple-carousel__arrow--left:before': {
        fontSize: "40px",
        fontWeight: 700
    }
})

const ParentBox = styled(Box)({
    position: 'relative',
    '&:hover .first-box': {
        opacity: 0,
    },
    '&:hover .second-box': {
        opacity: 1,
    },
    '& .second-box': {
        opacity: 0,
        position: 'absolute !important' as any,
        bottom: -35,
        left: -5,
    },
})
const ParentBox2 = styled(Box)({
    position: 'relative',
    '&:hover .first-box': {
        opacity: 0,
    },
    '&:hover .second-box': {
        opacity: 1,
    },
    '& .second-box': {
        opacity: 0,
        position: 'absolute !important' as any,
        bottom: -20,
        left: -5,
    },
})

const CustomStepper = styled(MobileStepper)({
    '& .MuiMobileStepper-dotActive': {
        backgroundColor: "#3E01B9 !important",
        width: "10px !important",
        height: "10px !important"
    },
    '& .MuiMobileStepper-dot': {
        width: "10px !important",
        height: "10px !important",
        margin: "0px 15px"
    }
})

const CustomCategoryBox = styled(Box)({
    '&.MuiBox-root:hover': {
        backgroundColor: '#3E01B9 !important',
        cursor: 'pointer'
    }
})

export const CardPrimary = (Props: any) => {
    return (
        <Box className="first-box">
            <img style={{ width: "240px" }} src={Props.data.image} alt="" />
            <Typography style={webStyle.sponsored}>Sponsored</Typography>
            <Box style={webStyle.card_description}>
                <Box style={{ marginLeft: "10px" }}>
                    <Typography style={webStyle.card_title}>Mika Finance</Typography>
                    <Box style={webStyle.member_box}>
                        <img src={members} style={webStyle.members_icon} alt="" />
                        <Typography style={webStyle.members}>125 members</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export const CardSecondary = (Props: any) => {
    return (
        <Box key={Props.index}>
            <img style={{ width: "240px" }} src={Props.data.image} alt="" />
            <Box style={webStyle.card_description}>
                <Typography style={webStyle.card_index}>{Props.index + 1}</Typography>
                <img src={Props.data.favicon} />
                <Box style={{ marginLeft: "10px" }}>
                    <Typography style={webStyle.card_title}>{Props.data.title}</Typography>
                    <Typography style={webStyle.card_type}>{Props.data.type}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export const ExploreCategory = (props: any) => {
    return (
        <Box style={{ marginLeft: "40px", marginBottom: "50px" }}>
            <Box style={{ display: "flex", marginBottom: "20px" }}>
                <Typography style={webStyle.category_heading_text}>
                    Explore by Category
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarousel
                swipeable={false}
                draggable={true}
                responsive={responsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    category.map((data, index) => {
                        return (
                            <CustomCategoryBox style={webStyle.category_main} key={index} onClick={() => props.handleGetCategory(data.title)} >
                                <img style={{ margin: "15px 25px", width: "", height: "" }} src={data.icon} />
                                <Typography style={{ color: "#FFFFFF", margin: "5px 25px" }}>{data.title}</Typography>
                            </CustomCategoryBox>
                        )
                    })
                }
            </CustomCarousel>;
        </Box>
    );
};

export const TopCreator = () => {
    return (
        <Box style={{ marginLeft: "40px", marginBottom: "50px" }}>
            <Box style={{ marginBottom: "20px" }}>
                <Box style={{ display: "flex", marginBottom: "20px" }}>
                    <Typography style={webStyle.category_heading_text}>
                        Top Creators
                    </Typography>
                    <img src={leftarrow} />
                </Box>
            </Box>
            <CustomCarouselPrimary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    PrimaryData.map((data, index) => {
                        return (
                            <CardSecondary data={data} index={index} />
                        )
                    })
                }
            </CustomCarouselPrimary>
        </Box>
    );
};

export const FeaturedCommunities = () => {
    return (
        <Box style={{ marginLeft: "40px", marginBottom: "50px" }}>
            <Box style={webStyle.category_heading}>
                <Typography style={webStyle.category_heading_text}>
                    Featured Communities
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarouselPrimary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    PrimaryData.map((data, index) => {
                        return (
                            <CardSecondary data={data} index={index} />
                        )
                    })
                }
            </CustomCarouselPrimary>
        </Box>
    );
};

export const HoverCardUI = (Props: any) => {
    return (
        <Box className="second-box" style={webStyle.card_hover}>
            <img src={Props.data.image} style={webStyle.image_hover} />
            <Typography style={webStyle.sponsored}>Sponsored</Typography>
            <Box style={webStyle.description_hover}>
                <Box style={webStyle.description_header}>
                    <img src={FavIcon3} style={webStyle.icon_hover} />
                    {Props.Finance && <img src={video} style={webStyle.video_icon} />}
                    <Typography style={webStyle.description_header_title}>Creating Pitches.</Typography>
                </Box>
                <Box>
                    <Typography style={{ color: "#ffffff", fontWeight: 400, fontSize: "15.7px", margin: "15px 7px" }}>
                        25 Strange Images From Outer Space NASA Does Not Want Us To See. By Val...
                        <span style={{ color: "#ffffff", fontWeight: 600, fontSize: "15.7px", textDecoration: "underline" }}>
                            Learn More
                        </span>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export const Anime = () => {
    return (
        <Box style={webStyle.carousel_container}>
            <Box style={webStyle.category_heading_1}>
                <Typography style={webStyle.category_heading_text}>
                    Anime
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarouselSecondary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    animeData.map((data, index) => {
                        return (
                            <ParentBox2 key={index}>
                                <CardPrimary data={data} />
                                <HoverCardUI data={data} Finance={true} />
                            </ParentBox2>
                        )
                    })
                }
            </CustomCarouselSecondary>
        </Box>
    );
};

export const SkydivingatEarth = () => {
    return (
        <Box style={webStyle.carousel_container}>
            <Box style={webStyle.category_heading_1}>
                <Typography style={webStyle.category_heading_text}>
                    Skydiving at Earth
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarouselSecondary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    secondaryData.map((data, index) => {
                        return (
                            <ParentBox2 key={index}>
                                <CardPrimary data={data} />
                                <HoverCardUI data={data} Finance={true} />
                            </ParentBox2>
                        )
                    })
                }
            </CustomCarouselSecondary>
        </Box>
    );
};
export const Events = () => {
    return (
        <Box style={webStyle.carousel_container}>
            <Box style={webStyle.category_heading_1}>
                <Typography style={webStyle.category_heading_text}>
                    Events
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarouselSecondary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    secondaryData.map((data, index) => {
                        return (
                            <ParentBox key={index}>
                                <Box className="first-box">
                                    <img style={{ width: "240px" }} src={data.image} alt="" />
                                    <Typography style={webStyle.status}>Online</Typography>
                                    <Typography style={webStyle.date}>Sun 27th Aug . At 17:00</Typography>
                                    <Box style={webStyle.card_detail}>
                                        <img src={data.favicon} style={{ width: "24px", height: "24px" }} />
                                        <Box style={{ marginLeft: "10px" }}>
                                            <Typography style={webStyle.card_title_data}>{data.title}</Typography>
                                            <Typography style={webStyle.card_user_type}>{data.user}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="second-box" style={webStyle.card_hover}>
                                    <img src={data.image} style={webStyle.image_hover} />
                                    <Typography style={webStyle.status}>Online</Typography>
                                    <Box style={webStyle.description_hover}>
                                        <Box style={webStyle.date_and_member}>
                                            <Typography style={webStyle.date_hover}>Sun 27th Aug . At 17:00</Typography>
                                            <Box style={{
                                                alignItems: "center",
                                                display: "flex",
                                                padding: "3px 10px",
                                                borderRadius: "8px",
                                                backgroundColor: "#292D32",
                                            }}>
                                                <img src={members} style={{
                                                    marginRight: "5px",
                                                    width: "14px",
                                                    height: "14px",
                                                }} />
                                                <Typography style={{
                                                    fontFamily: "Montserrat",
                                                    color: "#ffffff",
                                                    fontSize: "12px",
                                                }}>126</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.description_header}>
                                            <img src={FavIcon3} style={webStyle.icon_hover} />
                                            <Typography style={webStyle.description_header_title}>Creating Pitches.</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{
                                                margin: "15px 7px",
                                                color: "#ffffff",
                                                fontWeight: 400,
                                                fontSize: "15.7px",
                                            }}>
                                                25 Strange Images From Outer Space NASA Does Not Want Us To See. By Val...
                                                <span style={{
                                                    textDecoration: "underline",
                                                    color: "#ffffff",
                                                    fontWeight: 600,
                                                    fontSize: "15.7px",
                                                }}>
                                                    Learn More
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </ParentBox>
                        )
                    })
                }
            </CustomCarouselSecondary>
        </Box>
    );
};

export const Finance = () => {
    return (
        <Box style={webStyle.carousel_container}>
            <Box style={webStyle.category_heading_1}>
                <Typography style={webStyle.category_heading_text}>
                    Finance
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarouselSecondary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    secondaryData.map((data, index) => {
                        return (
                            <ParentBox2 className="asdf" key={index}>
                                <Box className="first-box">
                                    <img style={{ width: "240px" }} src={data.image} alt="" />
                                    <Typography style={webStyle.sponsored}>Sponsored</Typography>
                                    <Box style={webStyle.card_description}>
                                        <Box style={{ marginLeft: "10px" }}>
                                            <Typography style={webStyle.card_title}>Mika Finance</Typography>
                                            <Box style={webStyle.member_box}>
                                                <img src={members} style={webStyle.members_icon} alt="" />
                                                <Typography style={webStyle.members}>125 members</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <HoverCardUI data={data} Finance={true} />
                            </ParentBox2>
                        )
                    })
                }
            </CustomCarouselSecondary>
        </Box>
    );
};
export const TrendingCommunities = () => {
    return (
        <Box style={webStyle.carousel_container}>
            <Box style={webStyle.category_heading_1}>
                <Typography style={webStyle.category_heading_text}>
                    Top Trending communities
                </Typography>
                <img src={leftarrow} />
            </Box>
            <CustomCarouselSecondary
                swipeable={false}
                draggable={true}
                responsive={creatorResponsive}
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
            >
                {
                    secondaryData.map((data, index) => {
                        return (
                            <Box key={index}>
                                <img style={{ width: "240px" }} src={data.image} alt="" />
                                <Box style={webStyle.card_description}>
                                    <Box style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
                                        <img src={data.favicon} style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="" />
                                        <Typography style={{ color: "#ffffff", fontSize: "14px", fontWeight: 500 }}>Food on Plate</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
            </CustomCarouselSecondary>
        </Box>
    );
};
export default class DiscoverPageMember extends DiscoverPageMemberController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
        return (
            <Box style={{ height: "100%" }}>
                <Box style={webStyle.home}>
                    <MemberNavigationMenu {...this.props} />
                    <Box style={{ width: `calc(100vw - 295px)` }}>
                        <Box style={webStyle.heading}>
                            <Typography style={webStyle.category_heading_text}>
                                Discover
                            </Typography>
                            <img src={leftarrow} />
                            <Typography style={webStyle.category_heading_text}>
                                {this.state.category}
                            </Typography>
                        </Box>
                        {
                            !this.state.category ? (
                                <>
                                    <Box style={{ margin: "0px 40px", position: "relative" }}>
                                        <AutoPlaySwipeableViews
                                            index={this.state.activeStep}
                                            onChangeIndex={this.handleChangeStep}
                                            enableMouseEvents
                                            interval={5000}
                                        >
                                            {tutorialSteps.map((step, index) => (
                                                <Box className="123456" key={index}>
                                                    <img style={webStyle.img1} src={step.imgPath} key={index} />
                                                </Box>
                                            ))}

                                        </AutoPlaySwipeableViews>
                                        <Grid item xs={12} sm={6} md={4} style={webStyle.carouseldataStyle}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <img style={{ width: '54px', height: '54px' }} src={tutorialSteps[this.state.activeStep].logoImg} />
                                                <Typography variant="h4" style={webStyle.title}>
                                                    {tutorialSteps[this.state.activeStep].title}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" style={webStyle.content} >{tutorialSteps[this.state.activeStep].label}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={webStyle.stepperContainer}>
                                            <CustomStepper
                                                style={webStyle.stepper}
                                                variant="dots"
                                                steps={tutorialSteps.length}
                                                position="static"
                                                activeStep={this.state.activeStep}
                                                backButton={undefined}
                                                nextButton={undefined}
                                            />
                                        </Grid>
                                    </Box>
                                    <ExploreCategory handleGetCategory={this.handleGetCategory} />
                                    <TopCreator />
                                    <FeaturedCommunities />
                                    <Anime />
                                    <SkydivingatEarth />
                                    <Events />
                                    <Finance />
                                    <TrendingCommunities />
                                </>
                            ) : (
                                <Grid container style={{ paddingInline: 20 }}>
                                    {PrimaryData.map((data, index) => (
                                        <CustomGrid item xs={12} sm={6} md={4} lg={3} key={index}>
                                            <Box key={index} style={{ width: 240, margin: 'auto', marginBottom: "30px" }}>
                                                <img style={{ width: "100%", height: "130px" }} src={data.image} alt="" />
                                                <Box style={webStyle.card_description_1}>
                                                    <Box style={{ marginLeft: "10px" }}>
                                                        <Typography style={webStyle.card_title}>{data.title}</Typography>
                                                        <Box style={webStyle.member_box}>
                                                            <img src={members} style={webStyle.members_icon} alt="" />
                                                            <Typography style={webStyle.members}>125 members</Typography>
                                                        </Box>
                                                    </Box>
                                                    <img src={arrow} style={{ marginRight: "10px" }} />
                                                </Box>
                                            </Box>
                                        </CustomGrid>
                                    ))}
                                </Grid>
                            )
                        }
                    </Box>
                </Box>
            </Box>
        );
    }
}



const webStyle = {
    home: {
        backgroundColor: '#090909',
        display: "flex",
        minHeight: '100vh',
    },
    heading: {
        color: "#FFFFFF",
        fontSize: "22px",
        fontWeight: 500,
        margin: "30px 40px",
        display: "flex"
    },
    stepper: {
        background: "transparent",
    },
    stepperContainer: {
        height: '8vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    carouseldataStyle: {
        position: 'absolute',
        bottom: "10vh",
        left: '56px',
        width: "100%",
    } as React.CSSProperties,
    title: {
        color: "#FFF",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "40px",
        letterSpacing: "0.123px",
        textTransform: "capitalize",
        marginLeft: '16px'
    } as React.CSSProperties,
    card_description: {
        display: "flex",
        alignItems: "center",
        margin: "15px -10px"
    },
    card_description_1: {
        display: "flex",
        alignItems: "center",
        margin: "15px -10px",
        justifyContent: "space-between"
    } as React.CSSProperties,
    card_detail: {
        display: "flex",
        alignItems: "center",
        margin: "15px 0px"
    },
    card_title: {
        color: "#FFFFFF",
        fontWeight: 500,
        fontSize: "16px",
    },
    card_title_data: {
        color: "#FFFFFF",
        fontWeight: 500,
        fontSize: "12px",
        width: "186px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontFamily: "Poppins",
    } as React.CSSProperties,
    card_type: {
        color: "#6D6D6D",
        fontWeight: 400,
        fontSize: "16px"
    },
    card_user_type: {
        color: "#6D6D6D",
        fontWeight: 500,
        fontSize: "10px"
    },
    content: {
        color: "#FFF",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.123px"
    } as React.CSSProperties,
    card_index: {
        color: "#868686",
        fontSize: "24px",
        fontWeight: 600,
        marginLeft: "10px",
        marginRight: "15px"
    },
    category_main: {
        width: "182px",
        height: "124px",
        backgroundColor: "#282828",
        borderRadius: "8px"
    },
    category_heading: {
        display: "flex",
        marginBottom: "20px"
    },
    category_heading_1: {
        display: "flex",
        marginLeft: "20px"
    },
    category_heading_text: {
        color: "#FFFFFF",
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Poppins"
    },
    text_color: {
        color: "#FFFFFF",
        textAlign: "center" as const,
        fontSize: "22px",
        maxWidth: "725px",
    },
    info_icon: {
        width: "26.67px",
        height: "26.67px",
        transform: 'translate(0px,4px)'
    },
    discover_button: {
        width: "439px",
        height: "64px",
        backgroundColor: "#3E01B9",
        marginTop: "20px",
        fontSize: "18px",
        color: "#FFFFFF",
        fontWeight: 600,
        fontFamily: "Montserrat"
    },
    img1: {
        width: '100%',
        height: "406px",
        objectFit: "cover"
    } as React.CSSProperties,
    sponsored: {
        backgroundColor: '#474747',
        width: '90px',
        borderRadius: '8px',
        fontSize: '12px',
        textAlign: 'center',
        padding: '2px 0px',
        position: 'absolute',
        top: '10px',
        left: '9px',
        color: "#FFFFFF",
        fontWeight: 400,
        fontFamily: "Montserrat"
    } as React.CSSProperties,
    members: {
        color: "#FFFFFF",
        fontWeight: 400,
        fontFamily: "Montserrat",
        fontSize: '12px',
    },
    members_icon: {
        width: "12px",
        height: "12px"
    },
    member_box: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#202020",
        justifyContent: "space-evenly",
        width: "110px",
        height: "24px",
        borderRadius: "4px"
    },
    status: {
        backgroundColor: '#B50B8F',
        borderRadius: '8px',
        fontSize: '12px',
        textAlign: 'center',
        position: 'absolute',
        top: '10px',
        left: '9px',
        color: "#FFFFFF",
        fontWeight: 400,
        fontFamily: "Montserrat",
        padding: "5px 15px",
    } as React.CSSProperties,
    date: {
        backgroundColor: '#292D32',
        borderRadius: '8px',
        fontSize: '12px',
        textAlign: 'center',
        position: 'absolute',
        bottom: '70px',
        left: '10px',
        color: "#FFFFFF",
        fontWeight: 400,
        fontFamily: "Montserrat",
        padding: "4px 10px"
    } as React.CSSProperties,
    card_hover: {
        width: "265px",
        backgroundColor: "#282828",
        position: "relative",
        borderRadius: "8.97px",
        height: "285px",
    } as React.CSSProperties,
    image_hover: {
        width: "265px",
        height: "146px",
        borderRadius: "9.18px",
        objectFit: "cover"
    } as React.CSSProperties,
    description_hover: {

    } as React.CSSProperties,
    description_header: {
        display: "flex",
        alignItems: "center",
        marginTop: "5px"
    } as React.CSSProperties,
    description_header_title: {
        color: "#ffffff",
        fontSize: "17.94px",
        fontFamily: "Montserrat",
    } as React.CSSProperties,
    icon_hover: {
        width: "24.66px",
        height: "24.66px",
        margin: "0px 7px"
    } as React.CSSProperties,
    date_hover: {
        backgroundColor: '#292D32',
        borderRadius: '8px',
        fontSize: '12px',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 400,
        fontFamily: "Montserrat",
        padding: "4px 10px",
        width: "140px",
        marginRight: "10px"
    } as React.CSSProperties,
    date_and_member: {
        display: "flex",
        position: "absolute",
        top: "110px",
        alignItems: "center",
        left: "10px"
    } as React.CSSProperties,
    video_icon: {
        position: "absolute",
        width: "68px",
        height: "68px",
        top: "50%",
        left: "50%",
        transform: 'translate(-50%, -140%)'
    } as React.CSSProperties,
    carousel_container: {
        marginLeft: "20px",
        marginBottom: "50px"
    }
};



