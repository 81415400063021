import { IBlock } from "../../../framework/src/IBlock";

import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Home, Messages, Notifications, SettingsImg, Analytics, Events, Communities, CrossIcon, ImageDrop, Outlined, CardIcon, imgMessageIcon, imgNewPostIcon ,CheckIcon,tick2,dollar} from './assets';

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { webStyle } from './Communitylandingpage.web'
import Button from '@material-ui/core/Button';
import React from "react";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { isChrome } from 'react-device-detect';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
const regexUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

interface S {
  txtInputValue: string;
  drawerItems: any;
  webDrawer: boolean,
  Cards: any[],
  Open: boolean,
  switch: number,
  coverPhotos: any[]
  isDragOver: any,
  dataFile: any,
  imageUrl: any,
  files: any,
  Description: any;
  textcard: boolean;
  anchorEl: any;
  anchorEl2: any
  checked: any;
  selectedCoverphoto: any;
  postModal: any;
  PostImage: any;
  postHeading: any;
  postLink: any;
  postDescription: any;
  pricing: boolean;
  Pricingcards: any;
  CardDetails: any;

  PostheadingError: boolean;
  PostheadingErrorMsg: any;
  PostLink: any;
  PostLinkError: boolean;
  PostLinkErrorMsg: any;
  PostDescription: any;
  PosDescError: boolean;
  PostDescErrorMsg: any;
  postImgerror: boolean;
  postImgErrorMsg: any;
  active: any;
  video: boolean;
  // Customizable Area Start
  name: any;
  description: any;
  cover_pic: any;
  category_id: any;
  username: any;
  subscription_type: any;
  status: any;
  communityResponse: any;
  expanded: boolean;
  welcomeNoteText: string;
  videoThumb: any;
  welocomeNoteModalDetails: any;
  cancelResponse: any;
  isloading: boolean;
  src: any;
  isPlaying: any;
  isChrome: any;
  browserThumbnail: any;
  onDemandState: boolean;
  date: {
    day: string;
    month: string;
    year: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunitylandingpageController extends BlockComponent<
  Props,
  S,
  SS
> {
  fileInputRef: any;
  // Customizable Area Start
  keyUploadVedio: string = "";
  keyCommunity: string = "";
  keyWelocomeNote: string = "";
  keyCancelVedio: string = "";
  videoInputRef: any;
  thumbnailCanvasRef: any;
  thumbnailImageRef: any;
  vedioControlRef: any;
  browseRef: any;
  canvasRef: any;
  monthInput: any;
  yearInput: any;
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.fileInputRef = React.createRef();
    this.videoInputRef = React.createRef();
    this.thumbnailCanvasRef = React.createRef();
    this.thumbnailImageRef = React.createRef();
    this.vedioControlRef = React.createRef();
    this.browseRef = React.createRef();
    this.canvasRef = React.createRef();
    this.monthInput = React.createRef();
    this.yearInput = React.createRef();
   
    this.state = {
      txtInputValue: "",
      drawerItems: [{ name: "Home", Img: Home }, { name: "Message", Img: Messages }, { name: "Events", Img: Events }, { name: "Communities", Img: Communities }, { name: "Analytics", Img: Analytics }, { name: "Notifications", Img: Notifications }, { name: "Settings", Img: SettingsImg }],
      webDrawer: false,

      Cards: [{ id: 1, description: "To publish your community it's mandatory to upload a video showcasing about the community content to Zilion users.", buttoncontent: "Upload a Video", Upload: true, idx: 0, isCompleted: false, img: CardIcon },
      { id: 2, description: "Share a message as soon as a user joins your community", buttoncontent: "Write a Welcome Note", Upload: false, idx: 1, isCompleted: false, img: imgMessageIcon },
      { id: 3, description: "Let's create a new post or upload your 1st video for the members wholl be joining your community shortly!", buttoncontent: "Create a New Post", Upload: false, idx: 2, isCompleted: false, img: imgNewPostIcon },],
      Open: false,
      switch: 0,
      coverPhotos: [],
      Description: "",
      textcard: false,
      isDragOver: "",
      dataFile: {},
      imageUrl: "",
      files: "",
      anchorEl: null,
      anchorEl2: false,
      checked: false,
      selectedCoverphoto: "",
      postModal: false,
      postImgerror: false,
      postImgErrorMsg: "",

      PostheadingError: false,
      PostheadingErrorMsg: "",
      PostLink: "",
      PostLinkError: false,
      PostLinkErrorMsg: "",
      PostDescription: "",
      PosDescError: false,
      PostDescErrorMsg: "",
      PostImage: "",
      postHeading: "",
      postLink: "",
      postDescription: "",
      pricing: false,
      Pricingcards: [{ plans: "Basic", Price: 5, selected: false, planText: "/M/Y" }, { plans: "Elite", Price: 8, selected: false, planText: "/M" }, { plans: "Premium", Price: 50, selected: false, planText: "/Y" }, { plans: "Express", Price: 60, selected: false, planText: "/Y" }],
      CardDetails: [],
      active: 1,
      video: false
      // Customizable Area Start
      ,
      name: "",
      description: "",
      cover_pic: "",
      category_id: "",
      username: "",
      subscription_type: "",
      status: "",
      communityResponse: {},
      expanded: false,
      welcomeNoteText: "",
      videoThumb: "",
      welocomeNoteModalDetails: "",
      cancelResponse: "",
      isloading: false,
      src: "",
      isPlaying: true,
      isChrome: true,
      browserThumbnail: "",
      onDemandState: false,
      date: {
        day: '',
        month: '',
        year: '',
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    this.handlApiCommunityGet()
    this.setState({
      isChrome: true
    })
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
    
        const apiRequestCallData = {
          [this.keyWelocomeNote]: (data: any) => this.keyWelcomResponse(data),
          [this.keyCommunity]: (data: any) => this.keyCommunityResponse(data),
          [this.keyUploadVedio]: (data: any) => this.keyUploadVideoResponse(data),
          [this.keyCancelVedio]: (data: any) => this.keyUploadVideoCancelResponse(data)
        }

        const callback = apiRequestCallData[apiRequestCallId];
        
        if(callback !== undefined) {
            callback(responseJson)
        }
       }
    }
  }


  keyCommunityResponse = (response: any) => {
            this.setState({
               communityResponse: response.data
             });
  }


  keyUploadVideoResponse = (response: any) => {
    this.setState((state) => ({

      isloading: false,
      imageUrl: response.data.attributes.vedio,
      video: true,
      Cards: state.Cards.map((item) => {
        return this.cardVedioUploadHandler(item)
      })
    }))
}

keyUploadVideoCancelResponse = (response: any) => {
  this.setState({
    cancelResponse: response.data
  })
}

  keyWelcomResponse = (response:     any) => {
  console.log("state-->",this.state.Cards)
            const newCards = this.state.Cards.map((item:any) => {
              return this.cardWelcomeHandler(item)
            })
            this.setState((state) => ({
              welcomeNoteText: response.data.attributes.welcome_note,
              welocomeNoteModalDetails: response.data,
              Cards:newCards
            }))
  }

  ///////////////////////////

  cardVedioUploadHandler = (item: any) => {
    if (item.id === 1) {
      item.isCompleted = true;
      item.description = this.vedioDescription();
      item.buttoncontent = "Edit the video";
    }
    return item
  }

  cardWelcomeHandler = (item: any) => {
    if (item.id === 2) {
      item.isCompleted = true;
      item.description = this.welcomeNoteDescription();
      item.buttoncontent = "Edit the Welcome Note";
    }
    return item
  }

  vedioDescription = () => {
    return (
      <Typography>
        Nice! Video uploaded successfully
      </Typography>
    )
  }

  welcomeNoteDescription = () => {
    return (
      <Typography>
        Welcome note looks great!
      </Typography>
    )
  }


  Othercards = (props: any) => {

    return (
      <div>
        {props.video ?

          <div style={webStyle.SelectCoverImage}>
            <div style={{ backgroundColor: "#3618B1", borderRadius: "10px", padding: "10px", minHeight: "300px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 10px" }}>
                <Typography style={{ fontSize: "20px", fontWeight: 500 }} >Uploading Video</Typography>
                <img src={CrossIcon} onClick={this.handleClose} style={{ width: "40px", height: "40px" }} data-test-id="crossBtn"></img>
              </div>
              <div style={{ position: 'relative', borderRadius: "10px", overflow: 'hidden', backgroundColor: "black", maxHeight: "400px", minHeight: "400px" }}>
                {this.state.isloading ? (
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <CircularProgress style={{ color: "#fff" }} />
                  </div>
                ) : (
                      // <CheckCircleOutlinedIcon style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }} />
                      <img src={CheckIcon} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1, width: "4%" }} />
                )}
                {!this.state.isloading && (
                  <>
                    <video
                      width="100%"
                      style={{ borderRadius: "10px", backgroundColor: "black", maxHeight: "400px", objectFit: 'cover' }}
                      src={this.state.src}

                    >
                    </video>
                  </>
                )}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
            <button
                test-Id="clearCoverImg"
                title="Set Cover Image" color="primary"
                onClick={() => {
                  this.handleApiCancelVedio();
                  this.setState({ Open: false, imageUrl: "", video: false })
                }}
                style={{
                  width: '35%',
                  boxShadow: 'none',
                  margin: '20px 0px',
                  textDecoration: 'none !important',
                  backgroundColor: '#3E01B9',
                  color: '#ffffff',
                  border: 'none',
                  padding: "10px 20px",
                  cursor: 'pointer',
                  display: 'inline-block',
                  textAlign: 'center',
                  fontSize: '16px',
                  borderRadius: '4px',
                }}
              >
                Cancel
              </button>
            </div>
          </div>

          :

          <div style={webStyle.modalStyle} >
            <img src={CrossIcon} onClick={this.handleClose} style={{ width: "40px", height: "40px", position: "relative", left: "70%", marginTop: "20px" }}></img>
            <Grid item md={6} lg={6} >

              {
                this.state.switch === 1 && <div>
                  <Typography style={{ fontSize: "28px", fontWeight: 500, marginTop: "20px" }}>Upload Video/Picture</Typography>

                  <Typography style={{
                    ...webStyle.modalcontent,
                  }}>
                    To provide the best possible experience for our Zillion<br />
                    members, the maximum size of the video should not be<br />
                    more than 512MB
                  </Typography>

                  <Box>


                    <div style={props.imageUrl ? webStyle.dsiplayNone : webStyle.divAlign}>
                      <div >

                        <input
                          type="file"
                          id="fileInput"
                          ref={this.videoInputRef}
                          accept="image/,video/mp4,video/*"
                          style={{ display: 'none', }}
                          onChange={(e) => { this.handleFileInputChange(e) }}
                          data-testId="vedioInputTest"
                        />




                        <label htmlFor="fileInput">
                          <Box
                            onDrop={this.handleDrop}
                            onDragOver={this.handleDragOver}
                            style={{
                              padding: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              border: '1px dashed #ccc',
                              height: '240px',
                              display: 'flex',
                              flexDirection: "column",
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '8px',
                              borderColor: '#32569A',
                              backgroundColor: 'white',
                            }}
                          >
                            <Button

                              style={{ backgroundColor: props.isDragOver ? '#ccc' : 'inherit' }}
                            >
                              <img src={ImageDrop} width={26} height={24}></img>
                            </Button>
                            <Typography style={webStyle.Droptext}>  Drop your image here, or <span style={{ color: "#1C1C1C" }}> browse</span> </Typography>
                          </Box>

                        </label>
                      </div>
                    </div>
                    {props.imageUrl && 
                     this.browerFunction(props.imageUrl)
                    }
                   

                  </Box>

                  <button
                    disabled = {!this.state.src}
                    style={{
                      width: '100%',
                      boxShadow: 'none',
                      margin: '20px 0px',
                      textDecoration: 'none !important',
                      backgroundColor: '#1976D2',
                      color: '#ffffff',
                      border: 'none',
                      padding: '12px 24px',
                      cursor: 'pointer',
                      display: 'inline-block',
                      textAlign: 'center',
                      fontSize: '16px',
                      borderRadius: '4px',
                    }}
                    onClick={() => {
                      this.setState({
                        // textcard: true,
                        Open: true,
                        video: true,
                        isloading: true,
                      });
                      setTimeout(() => {
                        this.handleApiVedioUpload()
                      }, 1000)

                    }}
                    data-test-id="TestUploadVideoBtn"
                  >
                    Upload Video
                  </button>
                </div>
              }

              {
                this.state.switch === 2 && <>
                  <Typography style={{ fontSize: "28px", fontWeight: 500, marginTop: "20px" }}>Welcome Message</Typography>

                  <Typography style={webStyle.modalcontent}>
                    1st automated message for new <br />
                    community members.
                  </Typography>


                  <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
                    <Grid item md={1}>
                      <img src={Outlined} ></img>
                    </Grid>
                    <Grid item md={10}>
                      <Typography style={{ color: "#323548", fontSize: "14px", textAlign: "left" }}>Write a welcome note to your members</Typography>
                      <TextField

                        data-test-id="changeDescription"
                        onChange={this.handledescription}
                        fullWidth
                        multiline
                        rows={6}
                        value={this.state.welcomeNoteText}
                        placeholder="Eg:  We are the super fast growing....."
                        style={{ fontFamily: "Poppins,sans-serif" }}

                      />
                    </Grid>
                  </Grid>
                  <button 
                  test-Id="save-Message" 
                    onClick={this.welcomeFunction}
                    style={{ margin: "20px 0px",
                    width: '100%',
                    boxShadow: 'none',
                    textDecoration: 'none !important',
                    backgroundColor: '#1976D2',
                    color: '#ffffff',
                    border: 'none',
                    padding: '12px 24px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    textAlign: 'center',
                    fontSize: '16px',
                    borderRadius: '4px',
                     }}>
                    Save Message
                  </button>
                </>
              }


            </Grid>

          </div>



        }
      </div>


    )
  }

  ForNewPost = (props: any) => {
    return (
      <div style={{ ...webStyle.modalStyle, height: "auto" }} >
        <img src={CrossIcon} onClick={this.handleClosepostmodal} style={{ width: "40px", height: "40px", position: "relative", left: "70%", marginTop: "20px" }}></img>
        <Grid item md={6} lg={6}  style={{ display: 'flex', flexDirection: 'column' }}>

          <>
            <Typography style={{ fontSize: "28px", fontWeight: 500, marginTop: "20px" }}>Create a Post</Typography>

            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
              <Grid item md={1}>
                <img src={Outlined} ></img>
              </Grid>
              <Grid item md={10}>

                <Typography style={{ color: "#323548", fontSize: "14px", textAlign: "left" }}>Add picture or video</Typography>
                {
                  this.state.PostImage !== "" ?
                    <div style={{ display: "flex" }}>
                      <img src={URL.createObjectURL(this.state.PostImage)} style={{ width: "300px", height: "160px", borderRadius: "5px" }}></img>

                      <CloseRoundedIcon test-Id="PostUrl" onClick={() => this.setState({ PostImage: "" })} style={{ color: "#383838", margin: "auto 5px" }} />
                    </div>
                    :

                    <Button
                      disableRipple
                      component="label"
                    >
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0px 0px" }}>

                        <div style={webStyle.uploadphotobtn}>
                          Upload +
                          <input
                            accept="image/png, image/jpg, image/jpeg,"
                            type="file"
                            hidden
                            name="getimage"
                            test-Id="getpostimage"
                            onChange={(e: any) => this.updateFieldChanged(e)}
                          />
                        </div>
                      </div>

                    </Button>
                }
                {this.state.postImgerror && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.postImgErrorMsg}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
              <Grid item md={1}>
                <img src={Outlined} ></img>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ color: "#323548", fontSize: "14px", textAlign: "left" }}>Add Heading</Typography>
                <TextField
                  data-test-Id="PostHeading"
                  onChange={(e: any) => this.handlePostdescription(e)}
                  fullWidth
                  value={this.state.postHeading}
                  placeholder="A new world"
                  name="postHeading"
                />
                {this.state.PostheadingError && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PostheadingErrorMsg}
                  </FormHelperText>
                )}
              </Grid>

            </Grid>

            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
              <Grid item md={1}>
                <img src={Outlined} ></img>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ color: "#323548", fontSize: "14px", textAlign: "left" }}>Add Link</Typography>
                <TextField
                  data-test-Id="PostLink"
                  onChange={(e: any) => this.handlePostdescription(e)}
                  fullWidth
                  value={this.state.postLink}
                  placeholder="Eg: https://www.zilion.com"
                  name="postLink"
                />
                {this.state.PostLinkError && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PostLinkErrorMsg}
                  </FormHelperText>
                )}
              </Grid>

            </Grid>
            <Grid container spacing={1} style={{ backgroundColor: "white", borderRadius: "10px", margin: "10px auto" }}>
              <Grid item md={1}>
                <img src={Outlined} ></img>
              </Grid>
              <Grid item md={10}>
                <Typography style={{ color: "#323548", fontSize: "14px", textAlign: "left" }}>Add Description</Typography>
                <TextField
                  data-test-Id="PostDesc"
                  onChange={(e: any) => this.handlePostdescription(e)}
                  fullWidth

                  multiline
                  rows={4}
                  value={this.state.postDescription}
                  placeholder="Eg: We are the super fast growing....."
                  name="postDescription"
                />
                {this.state.PosDescError && (
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PostDescErrorMsg}
                  </FormHelperText>
                )}
              </Grid>

            </Grid>

            <Box>
              <Typography variant="subtitle1" style={{ margin: "10px 0px", textAlign: "start" }}>Post type</Typography>
              <Box sx={webStyle.ButtonGroup}>
              <Button variant="outlined" name="free" onClick={this.handleactive} style={{
                  // borderRadius: "20px", 
                  fontWeight: 600, color: "#FFF", backgroundColor: this.state.active === 1 ? "rgba(62, 1, 185, 1)" : "", border: this.state.active === 1 ? "" : "1px solid white", textTransform: "capitalize", fontSize: "20px", borderRadius: "50px"
                }} >Free</Button>


                <Button variant="outlined" test-Id="handlePricing" onClick={this.handleactive} style={{
                  fontWeight: 600, color: "#FFF", backgroundColor: this.state.active === 2 ? "rgba(62, 1, 185, 1)" : "", border: this.state.active === 2 ? "" : "1px solid white", textTransform: "capitalize", fontSize: "20px", borderRadius: "50px"
                }} >Members</Button>

                <Button variant="outlined" test-Id="onDemandTest" onClick={this.handleactive} style={{ fontWeight: 600, color: "#FFF", backgroundColor: this.state.active === 3 ? "rgba(62, 1, 185, 1)" : "", border: this.state.active === 3 ? "" : "1px solid white", textTransform: "capitalize", fontSize: "20px", borderRadius: "50px" }}>On-Demand</Button>
              </Box>
            </Box>
            <Box>
              {
                this.state.pricing && (

                  this.AllCards(this.state.Pricingcards)

                )
              }
            </Box>

            <Box>{this.state.onDemandState && this.onDemandFn()}</Box>
            <button
              test-Id="PostNow"
              color="primary"
              onClick={() => this.handlepostbutton()}
              style={{
                // width: '35%',
                boxShadow: 'none',
                margin: '20px 0px',
                textDecoration: 'none !important',
                backgroundColor: '#3E01B9',
                color: '#ffffff',
                border: 'none',
                padding: "10px 20px",
                cursor: 'pointer',
                display: 'inline-block',
                textAlign: 'center',
                fontSize: '16px',
                borderRadius: '4px',
                width: '100%',
              }}
            >
              Post Now
            </button>
          </>
        </Grid>

      </div>
    )
  }

  AllCards = (props: any) => {
    return (
      <div
        className="secondMemberCard"
        style={{
          display: "grid",
          gridTemplateColumns: " repeat(2, 1fr)",
          gridColumnGap: "8px",
          height: "30%",
        }}
      >
        {this.state.Pricingcards.map((data: any, index: any) => {
          console.log("fff", data)
          return (
            <Box
              key={index}
              test-Id="Pricingcards"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: "#3E01B9",
                color: "#FFF",
                borderRadius: "5px",
                marginTop: "8px",
                padding: "25px",
              }}
            >
              <div>
                <Typography style={webStyle.Postcardstypo}>{data.plans}</Typography>
              </div>
              <div>
                <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                  ${data.Price}
                  <span style={{ fontWeight: 400, fontSize: "10px" }}>{data.planText}</span>
                </Typography>
              </div>
              <div style={{ alignSelf: "flex-end", }}>
                <input
                  type="checkbox"
                  checked={data.selected}
                  onChange={() => this.handlePostCards(data.selected, index)}
                  style={data.selected ? { accentColor: "#b300b3", color: "white", border: "1px solid #b300b3", borderRadius: "5px" } : { accentColor: "black", color: "", border: "", borderRadius: "5px" }}
                />
              </div>
            </Box>
          );
        })}
      </div>
    );
  };

  onDemandFn = () => {
    return (
      <div style={{ marginTop: "12px", display: "flex", flexDirection: "column" }}>
        <div style={{display :"flex", gap : "2rem" , justifyContent : "start", marginBottom :"2rem"}}>
        <div>
        <img src={dollar} style={{width:"20px", height :"20px"}}></img>
        </div>
        <div>
        <Typography style={{ textAlign: "left", fontSize: "14px" }}>Add Pricing</Typography>
        <input type="text" placeholder="Add your price value" style={{ background: "none", color: "white", border: "none" , fontSize :"18px" }} />
        </div>
        </div>
        <span
          style={{
            transform: "translateY(-50%)",
            borderTop: "1px solid #ccc", 
            width: "100%",  
            color :"#a6a6a6" ,
            marginBottom : "1rem" 
          }}
        />
        <div style={{display :"flex", gap : "2rem" , justifyContent : "start", marginBottom :"2rem"}}>
        <div>
        <img src={tick2} style={{width:"20px", height :"20px"}}></img>
        </div>
        <div>
        <Typography style={{ textAlign: "left", fontSize: "18px" }}>Add % discount offer</Typography>
        <input type="text" placeholder="Add % discount value" style={{ background: "none", color: "white", border: "none" }} />
        </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <input
              type="checkbox"
            // checked={data.selected}
            // onChange={() => this.handlePostCards(data.selected, index)}
            // style={data.selected ? { accentColor: "#b300b3", color: "white", border: "1px solid #b300b3", borderRadius: "5px" } : { accentColor: "black", color: "", border: "", borderRadius: "5px" }}
            />

          </div>
          <div><Typography>Never Expire</Typography></div>
        </div>
        <Typography style={{textAlign: "left"}}>Offer expiry time</Typography>
        <div style={{ display: "flex" }}>
          <div>
            <input
              type="checkbox"
            // checked={data.selected}
            // onChange={() => this.handlePostCards(data.selected, index)}
            // style={data.selected ? { accentColor: "#b300b3", color: "white", border: "1px solid #b300b3", borderRadius: "5px" } : { accentColor: "black", color: "", border: "", borderRadius: "5px" }}
            />

          </div>

          <div><Typography>Customise Time</Typography></div>
          
        </div>
        <Typography style={{textAlign:"left"}}>Discount Expiry Date</Typography>
      </div>
    )
  }
  updateFieldChanged = (e: any) => {

    this.setState({ PostImage: e.target.files[0], postImgerror: false, postImgErrorMsg: "" })
  }

  handleClosepostmodal = () => {
    this.setState({ postModal: false })
  }
  handlePostdescription = (event: any) => {
    let name = event.target.name
    let value = event.target.value
    if (name === "postHeading") {
      this.setState({
        PostheadingError: false,
        PostheadingErrorMsg: ""
      })
    }
    if (name === "postDescription") {
      this.setState({
        PosDescError: false,
        PostDescErrorMsg: ""
      })
    }
    if (name === "postLink") {
      this.setState({
        PostLinkError: false,
        PostLinkErrorMsg: ""
      })
    }
    this.Bulkvalidation()
    this.setState((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  handlePostCards = (event: any, index: any) => {
    const updatecards = [...this.state.Pricingcards];
    updatecards[index].selected = !updatecards[index].selected;
    this.setState({ Pricingcards: updatecards })

  }
  handleClose = () => {
    this.setState({
      Open: false, isDragOver: "",
      dataFile: {},
      imageUrl: "",
      files: "",
      video: false
    });

  }

  Bulkvalidation = () => {

    if (!regexUrl.test(this.state.postLink)) {
      this.setState({
        PostLinkError: true,
        PostLinkErrorMsg: "Please enter valid url"
      })

    }
    if (this.state.PostImage === "") {
      this.setState({
        postImgerror: true,
        postImgErrorMsg: "please select the image"
      })
    }
    if (this.state.postDescription === "") {
      this.setState({
        PosDescError: true,
        PostDescErrorMsg: "please enter the description"
      })
    }
    if (this.state.postLink === "") {
      this.setState({
        PostLinkError: true,
        PostLinkErrorMsg: "please enter the link"
      })
    }
    if (this.state.postHeading === "") {
      this.setState({
        PostheadingError: true,
        PostheadingErrorMsg: "please enter the heading"
      })
    }

  }
  handleactive = (event: any) => {
    let name = event?.target.textContent
    if (name === "Free") {
      this.setState({ active: 1 })
    }

    if (name === "Members") {

      this.setState({ active: 2, pricing: true })
    } else {
      this.setState({ pricing: false })
    }
    if (name === "On-Demand") {
      this.setState({ active: 3, onDemandState: true })
    } else {
      this.setState({ onDemandState: false })
    }
  }
  handlepostbutton = () => {
    this.Bulkvalidation()
    if ((this.state.postHeading !== "" && !this.state.PosDescError) && (this.state.PostImage !== "" && !this.state.PostLinkError)) {

      let newdata = {
        Heading: this.state.postHeading,
        Description: this.state.postDescription,
        Postlink: this.state.postLink,
        image: this.state.PostImage
      }
      let updated = [...this.state.CardDetails, newdata]
      this.setState({ CardDetails: updated })
      this.setState({
        postHeading: "", postDescription: "", postLink: "", PostImage: "",
      })
      this.handleClosepostmodal()
    } else {
      this.Bulkvalidation()
    }

  }
  handleOpen = (event: any, index :any , isCompleted:any) => {
    if(index === 0 || isCompleted){
    this.setState({ Open: true, switch: event })
    }
  }
  handleUpdateCoverimage = () => {

  }
  handledescription = (e: any) => {
    this.setState({ welcomeNoteText: e.target.value })
  }
  handleAddCoverImage = (e: any) => {

    let newcover = e.target.files[0]


    this.setState({ coverPhotos: [...this.state.coverPhotos, { Img: newcover }] })
  }

  handleFileInputChange = async (event: any) => {
    const file = event.target?.files[0];

    if (file.size <= 530 * 1024000) {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      const objectUrl = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const videoDataUrl = e.target?.result;

        const videob = this.browseRef.current;
        videob.src = videoDataUrl;

        this.canvasRef.current.width = 200;
        this.canvasRef.current.height = 200;

        const context = this.canvasRef.current.getContext('2d');
        context.drawImage(video, 0, 0, this.canvasRef.current.width, this.canvasRef.current.height);

        const thumbDataUrl = this.canvasRef.current.toDataURL('image/png');
       
        this.setState({ browserThumbnail: thumbDataUrl });
      
      };

      reader.readAsDataURL(file);
      
      this.setState({
        imageUrl: file,
        videoThumb: objectUrl,
        src: URL.createObjectURL(file),
      })
    }

  };
  handleDragOver = (event: any) => {
    event.preventDefault();
  };
  handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];

    this.setState({
      imageUrl: file,
      src: URL.createObjectURL(file)
    })
  };




  handleanchorEl = () => {
    this.setState({
      anchorEl: null
    })
  }
  handleanchorElopen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleanchorElOptions = () => {
    this.setState({ anchorEl: null, switch: 2, Open: true })
  }


  // web events
  ///////////////////////////////////////
  welcomeFunction = () =>{
      this.setState({ textcard: true, Open: false });
      this.handleApiWelcomeNote();
  
  }
  browerFunction = (imageUrl: any) => {
    return (
      <div style={{ ...webStyle.divImg, position: 'relative' }}>
        <div style={{ display: isChrome ? '' : 'none' }}>
          <video ref={this.browseRef} width="300" height="200" />
          <canvas ref={this.canvasRef} style={{ display: 'none' }} />
        </div>
        {this.state.browserThumbnail &&
          <img
            src={URL.createObjectURL(imageUrl)}
            alt="file"
            style={{
              ...webStyle.AlignImg, position: 'relative', top: 0, left: 0,
              display: isChrome ? 'none' : '',
            }} />
        }

      </div>
    )
  }
  handleWelcomeNoteDelete = () => {
    this.setState({ welcomeNoteText: "", textcard: false, anchorEl: false })
  }
  toggleReadMore = () => {
    this.setState({ expanded: !this.state.expanded });
  };


  handlApiCommunityGet = async () => {
    const token = await getStorageData("authToken");
    const id = await getStorageData("CommunityForumId");

    const header = {
      token: token,
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyCommunity = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.communitySetupEndPoint + `/${id}`
    );
    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleApiVedioUpload = async () => {
    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };
    const data = {
      "community_forum_id": id,
      "video": this.state.imageUrl
    }

    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );



    this.keyUploadVedio = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.vedioUploadEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleApiWelcomeNote = async () => {
    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };
    const data = {
      "community_forum_id": id,
      "welcome_note": this.state.welcomeNoteText
    }

    const formData = new FormData();

    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyWelocomeNote = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.welcomeNoteEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleApiCancelVedio = async () => {

    const id = await getStorageData("CommunityForumId");
    const header = {
      "token": await getStorageData("authToken")
    };
    const formData = new FormData();
    formData.append("id", id)

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyCancelVedio = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.vedioCancelEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleVedioControls = () => {
    const video = this.vedioControlRef?.current
    if (!video) return;
    if (this.state.isPlaying) {
      video.play();
    } else {
      video.pause();
    }

    this.setState({ isPlaying: !this.state.isPlaying });
  }



  

  // Customizable Area End
}
