Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "contentmanagement3";
exports.labelBodyText = "contentmanagement3 Body";

exports.btnExampleTitle = "CLICK ME";


exports.vedioUploadEndPoint = "bx_block_communityforum/add_video";
exports.formDataApiContentType = "multipart/form-data";
exports.createCommunityEndPoint = "communityforum/community_forums";
exports.communitySetupEndPoint = "bx_block_communityforum/community_forums";
exports.welcomeNoteEndPoint = "bx_block_communityforum/add_welcome_note";
exports.vedioCancelEndPoint = "bx_block_communityforum/cancel_video";
// Customizable Area End